import { Dropdown, Layout, Menu } from "antd";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
const { Header, Content } = Layout;

const LayoutComponent = (props) => {
  let history = useHistory();

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("email");
    Cookies.remove("name");
    Cookies.remove("role");
    history.push("/");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/alumni/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          {Cookies.get("token") &&
            Cookies.get("name") !== undefined &&
            Cookies.get("role") !== "admin" && (
              <>
                <Dropdown overlay={menu} placement="bottom">
                  <Menu.Item key="4" style={{ marginLeft: "auto" }}>
                    {Cookies.get("name")}
                  </Menu.Item>
                </Dropdown>
              </>
            )}

          {Cookies.get("token") &&
            Cookies.get("name") !== undefined &&
            Cookies.get("role") === "admin" && (
              <Menu.Item key="4" style={{ marginLeft: "auto" }}>
                <Link to="/admin/alumni">{Cookies.get("name")}</Link>
              </Menu.Item>
            )}
          {Cookies.get("token") === undefined && (
            <>
              <Menu.Item key="2" style={{ marginLeft: "auto" }}>
                <Link to="/register">Register</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/login">Login</Link>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Header>
      <Content
        className="layout-home"
        style={{
          minHeight: "90vh",
          padding: "0",
        }}
      >
        <div className="site-layout-content">{props.content}</div>
      </Content>
    </Layout>
  );
};

export default LayoutComponent;
