import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";
import Verify from "../components/Auth/Verify";
import Home from "../components/Home";
import LayoutComponent from "../components/layout/LayoutComponent";
import LayoutProfile from "../components/layout/LayoutProfile";
import LayoutAdmin from "../components/layout/LayoutAdmin";
import Profile from "../components/Pages/Alumni/Profile";
import AdminTable from "../components/Pages/Admin/Table/AdminTable";
import AlumniDetail from "../components/Pages/Admin/Table/AlumniDetail";
import AdminChart from "../components/Pages/Admin/Chart/AdminChart";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/register" exact>
          <LayoutComponent content={<Register />}></LayoutComponent>
        </Route>
        <Route path="/verify" exact>
          <Verify />
        </Route>
        <Route path="/login" exact>
          <LayoutComponent content={<Login />}></LayoutComponent>
        </Route>
        <Route path="/alumni/profile" exact>
          <LayoutProfile content={<Profile />}></LayoutProfile>
        </Route>
        <Route path="/admin/dashboard" exact>
          <LayoutAdmin content={<AdminChart />}></LayoutAdmin>
        </Route>
        <Route path="/admin/alumni" exact>
          <LayoutAdmin content={<AdminTable />}></LayoutAdmin>
        </Route>
        <Route path="/admin/alumni/:id" exact>
          <LayoutAdmin content={<AlumniDetail />}></LayoutAdmin>
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
