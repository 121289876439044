import ActivityChart from "./ActivityChart";
import EntranceChart from "./EntranceChart";
import GatheringChart from "./GatheringChart";
import GenderChart from "./GenderChart";
import InstitutionNameChart from "./InstitutionNameChart";
import InstitutionTypeChart from "./InstitutionTypeChart";
import styles from "./AdminChart.module.css";

const AdminChart = () => {
  return (
    <>
      <div className={styles["first-chart"]}>
        <GenderChart />
        <ActivityChart />
      </div>
      <div className={styles["second-chart"]}>
        <InstitutionTypeChart />
        <EntranceChart />
      </div>
      <div className={styles["third-chart"]}>
        <InstitutionNameChart />
      </div>
      <div className={styles["fourth-chart"]}>
        <GatheringChart />
      </div>
    </>
  );
};

export default AdminChart;
