import { Input, Modal } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { useContext } from "react";
import * as Yup from "yup";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
import ErrorText from "../../UI/ErrorText";

const initialValues = {
  activityType: "COLLEGE_PREPARATION",
  cprp_type: "",
  cprp_duration: "",
};

const validationSchema = Yup.object({
  activityType: Yup.string().required("harap diisi"),
  cprp_type: Yup.string().required("harap diisi"),
  cprp_duration: Yup.string().required("harap diisi"),
});

const AddCollegePreparation = (props) => {
  const {
    showModal,
    setShowModal,
    responseText,
    setResponseText,
    setFetchStatus,
  } = useContext(AlumniContext);
  const { REACT_APP_HOST_API } = process.env;

  const formik = useFormik({
    initialValues,
    onSubmit: (value) => {
      axios
        .post(
          REACT_APP_HOST_API + "/alumni/activity",
          {
            activityType: value.activityType,
            cprpType: value.cprp_type,
            cprpDuration: value.cprp_duration,
          },
          { headers: { Authorization: "Bearer " + decryptToken("token") } }
        )
        .then((res) => {
          setResponseText(res.data.message);
          setShowModal(true);
          formik.resetForm();
          setFetchStatus(true);
        })
        .catch((err) => {
          setResponseText("Gagal Input Data " + err.message);
          setShowModal(true);
        });

      setFetchStatus(false);
    },
    validationSchema,
  });

  const handleOk = () => {
    setShowModal(false);
    props.onSubmit();
  };

  const handleCancel = () => {
    setShowModal(false);
    props.onSubmit();
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group" style={{ display: "none" }}>
          <label htmlFor="activityType">Jenis Aktifitas</label>
          <Input
            type="text"
            id="activityType"
            name="activityType"
            readOnly
            value={formik.values.activityType}
            required
          ></Input>
          {formik.errors.activityType && formik.touched.activityType && (
            <ErrorText>{formik.errors.activityType}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="cprp_type">Tipe Persiapan</label>
          <select
            type="text"
            id="cprp_type"
            name="cprp_type"
            {...formik.getFieldProps("cprp_type")}
            required
          >
            <option value="">---</option>
            <option value="MANDIRI">Mandiri</option>
            <option value="BIMBEL">Bimbel</option>
          </select>
          {formik.errors.cprp_type && formik.touched.cprp_type && (
            <ErrorText>{formik.errors.cprp_type}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="cprp_duration">Durasi Persiapan</label>
          <Input
            type="text"
            id="cprp_duration"
            name="cprp_duration"
            placeholder="contoh : 1 tahun 3 bulan"
            {...formik.getFieldProps("cprp_duration")}
          />
          {formik.errors.cprp_duration && formik.touched.cprp_duration && (
            <ErrorText>{formik.errors.cprp_duration}</ErrorText>
          )}
        </div>
        <button type="submit" className="button">
          Submit
        </button>
      </form>
      {showModal && (
        <Modal visible={showModal} onOk={handleOk} onCancel={handleCancel}>
          <p>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default AddCollegePreparation;
