import { useState, useEffect } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { decryptToken } from "../../../Helper";

const InstitutionTypeChart = () => {
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await axios.get(REACT_APP_HOST_API + "/admin/dashboard", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });

    setDataChart({
      labels: data.data.institutionTypePrecentage.map((value) => {
        if (value.institution_type === "PTS") {
          return "Swasta";
        }
        if (value.institution_type === "PTN") {
          return "Negri";
        }
        if (value.institution_type === "PTSD") {
          return "Swasta Diniyyah";
        }
        if (value.institution_type === "PTLU") {
          return "Universitas Luar Negri";
        }
      }),
      datasets: [
        {
          label: "%",
          data: data.data.institutionTypePrecentage.map(
            (value) => value.precentage
          ),
          backgroundColor: ["#18978F", "#FF7396", "#1684C3", "#F0A500"],
        },
      ],
    });
  };

  return (
    <div className="chart-wrapper chart-institution-type">
      <h2>Jenis Kuliah</h2>
      <Pie data={dataChart} />
    </div>
  );
};

export default InstitutionTypeChart;
