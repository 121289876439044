import { Modal, Button, Input } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import Backdrop from "../UI/Backdrop";
import styles from "./Verify.module.css";

const Verify = () => {
  const [input, setInput] = useState({
    email: Cookies.get("email"),
    token: 0,
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  const { REACT_APP_HOST_API } = process.env;

  const handleChange = (param) => {
    setInput({ ...input, [param.target.name]: param.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    axios
      .post(REACT_APP_HOST_API + "/verify", {
        email: input.email,
        token: input.token,
      })
      .then((response) => {
        setIsLoading(false);
        setResponseText(`${response.data.message}! 
silahkan ke halaman login`);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setResponseText(err.message);
        setShowErrorModal(true);
      });

    setInput({
      email: "",
      token: 0,
    });
  };

  const handleSuccessOk = () => {
    setShowSuccessModal(false);
    history.push("/login");
  };

  const handleErrorOk = () => {
    setShowErrorModal(false);
  };

  const handleCancel = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
  };

  return (
    <div className="verify-container">
      <form className="form verify" onSubmit={handleSubmit}>
        <h2 style={{ textAlign: "center", fontSize: 19 }}>
          Silahkan Masukkan Kode Verifikasi
        </h2>
        <div className="form-group">
          <label htmlFor="email">Email : </label>
          <Input
            type="email"
            name="email"
            value={input.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="token">Token : </label>
          <Input
            type="number"
            name="token"
            value={input.token}
            onChange={handleChange}
            required
          />
        </div>
        <button className={styles.button} type="submit">
          Submit
        </button>
      </form>
      {isLoading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}

      {showSuccessModal && (
        <Modal
          visible={showSuccessModal}
          onOk={handleSuccessOk}
          closable={false}
          footer={[
            <Button key="ok" type="primary" onClick={handleSuccessOk}>
              Ok
            </Button>,
          ]}
        >
          <p style={{ fontSize: 18, textAlign: "center" }}>{responseText}</p>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          visible={showErrorModal}
          onOk={handleErrorOk}
          onCancel={handleCancel}
        >
          <p style={{ fontSize: 18, textAlign: "center" }}>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default Verify;
