import { Button, Dropdown, Menu, Modal, Table } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
import Backdrop from "../../UI/Backdrop";
import Card from "../../UI/Card";
import AddFormalEducation from "./AddFormalEducation";
import AddInformalEducation from "./AddInformalEducation";
import styles from "./Profile.module.css";

const Education = () => {
  const [formalEducation, setFormalEducation] = useState([]);
  const [informalEducation, setInformalEducation] = useState([]);
  const [showFormalModal, setShowFormalModal] = useState(false);
  const [showInformalModal, setShowInformalModal] = useState(false);
  const [showCancelFormalModal, setShowCancelFormalModal] = useState(false);
  const [showCancelInformalModal, setShowCancelInformalModal] = useState(false);
  const [formalId, setFormalId] = useState("");
  const [informalId, setInformalId] = useState("");
  const {
    fetchStatus,
    setFetchStatus,
    isLoading,
    setIsLoading,
    dataAlumni,
    setDataAlumni,
  } = useContext(AlumniContext);

  const { REACT_APP_HOST_API } = process.env;

  let history = useHistory();

  useEffect(() => {
    fetchData();
  }, [fetchStatus]);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      let { data } = await axios.get(REACT_APP_HOST_API + "/alumni", {
        headers: { Authorization: "Bearer " + decryptToken("token") },
      });
      setIsLoading(false);
      setDataAlumni(data[0]);
      setFormalEducation(data[0].formalEducations);
      setInformalEducation(data[0].informalEducations);
    } catch (err) {
      setIsLoading(false);
      alert(err.message);
      history.push("/login");
    }
  };

  const handleDeleteFormal = (id) => {
    setFormalId(id);
    setShowCancelFormalModal(true);
  };

  const handleDeleteInformal = (id) => {
    setInformalId(id);
    setShowCancelInformalModal(true);
  };

  const handleSubmitFormal = () => {
    setShowFormalModal(false);
  };
  const handleSubmitInformal = () => {
    setShowInformalModal(false);
  };

  const handleCancel = () => {
    setShowFormalModal(false);
    setShowInformalModal(false);
    setShowCancelFormalModal(false);
    setShowCancelInformalModal(false);
  };

  const handleFormalModal = () => {
    setShowFormalModal(true);
  };

  const handleInformalModal = () => {
    setShowInformalModal(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleFormalModal}>
        Formal
      </Menu.Item>
      <Menu.Item key="2" onClick={handleInformalModal}>
        Informal
      </Menu.Item>
    </Menu>
  );

  const formalColumns = [
    { title: "Nama Universitas", dataIndex: "institution", align: "center" },
    {
      title: "Jenis Universitas",
      dataIndex: "institution_type",
      align: "center",
    },
    { title: "Lulus", dataIndex: "year", align: "center" },
    { title: "Jurusan", dataIndex: "major", align: "center" },
    { title: "Jenjang", dataIndex: "degree", align: "center" },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <>
          <Button
            type="danger"
            size="small"
            shape="round"
            onClick={() => handleDeleteFormal(id)}
          >
            Delete
          </Button>
          <Modal
            visible={showCancelFormalModal}
            onOk={() => {
              axios
                .delete(
                  REACT_APP_HOST_API + "/alumni/education/formal/" + formalId,
                  {
                    headers: {
                      Authorization: "Bearer " + decryptToken("token"),
                    },
                  }
                )
                .then(() => {
                  setFetchStatus(true);
                });

              setFetchStatus(false);
              setShowCancelFormalModal(false);
            }}
            onCancel={handleCancel}
          >
            <p className="content-modal">yakin ingin menghapus ?</p>
          </Modal>
        </>
      ),
    },
  ];

  const informalColumns = [
    { title: "Nama Institusi", dataIndex: "institution", align: "center" },
    { title: "Lulus", dataIndex: "year", align: "center" },
    { title: "Jurusan", dataIndex: "major", align: "center" },
    { title: "Durasi", dataIndex: "duration", align: "center" },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      render: (id) => (
        <>
          <Button
            type="danger"
            size="small"
            shape="round"
            onClick={() => handleDeleteInformal(id)}
          >
            Delete
          </Button>
          <Modal
            visible={showCancelInformalModal}
            onOk={() => {
              axios
                .delete(
                  REACT_APP_HOST_API +
                    "/alumni/education/informal/" +
                    informalId,
                  {
                    headers: {
                      Authorization: "Bearer " + decryptToken("token"),
                    },
                  }
                )
                .then(() => {
                  setFetchStatus(true);
                });

              setFetchStatus(false);
              setShowCancelInformalModal(false);
            }}
            onCancel={handleCancel}
          >
            <p className="content-modal">yakin ingin menghapus ?</p>
          </Modal>
        </>
      ),
    },
  ];

  const formalKeyData = formalEducation.map(({ ...item }) => ({
    ...item,
    key: item.id,
  }));

  const informalKeyData = informalEducation.map(({ ...item }) => ({
    ...item,
    key: item.id,
  }));

  return (
    <section className="education">
      {/* header */}
      <div className={styles["profile-header"]}>
        <h1 style={{ fontSize: "1.3rem" }}>Pendidikan</h1>
        <Dropdown overlay={menu} placement="bottom">
          <Button
            type="primary"
            style={{ display: "flex", alignItems: "center" }}
          >
            Tambah Pendidikan
          </Button>
        </Dropdown>
      </div>
      {/* formal section */}
      {formalKeyData.length > 0 && (
        <Card>
          <Table
            size="small"
            title={() => <h1 className="table-title">Formal</h1>}
            dataSource={formalKeyData}
            columns={formalColumns}
            pagination={false}
          ></Table>
        </Card>
      )}
      {/* informal section */}
      {informalKeyData.length > 0 && (
        <Card>
          <Table
            size="small"
            title={() => <h1 className="table-title">Informal</h1>}
            dataSource={informalKeyData}
            columns={informalColumns}
            pagination={false}
          ></Table>
        </Card>
      )}
      {/* universal */}
      <Modal
        title="Add Formal Education"
        visible={showFormalModal}
        onCancel={handleCancel}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <AddFormalEducation onSubmit={handleSubmitFormal} />
      </Modal>

      <Modal
        title="Add Informal Education"
        visible={showInformalModal}
        onCancel={handleCancel}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <AddInformalEducation onSubmit={handleSubmitInformal} />
      </Modal>
      {isLoading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}
    </section>
  );
};

export default Education;
