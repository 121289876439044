import { Button, Dropdown, Menu, Modal } from "antd";
import { useContext, useState } from "react";
import AddBusiness from "./AddBusiness";
import AddCollegePreparation from "./AddCollegePreparation";
import AddEducationBusiness from "./AddEducationBusiness";
import AddWork from "./AddWork";
import BusinessTable from "./BusinessTable";
import CollegePreparationTable from "./CollegePreparationTable";
import EducationBusinessTable from "./EducationBusinessTable";
import HousePreparationTable from "./HousePreparationTable";
import WorkTable from "./WorkTable";

import axios from "axios";
import { useEffect } from "react";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
import styles from "./Profile.module.css";

const Activity = () => {
  const [showWorkModal, setShowWorkModal] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [showEducationBusinessModal, setShowEducationBusinessModal] =
    useState(false);
  const [showCollegePreparationModal, setShowCollegePreparationModal] =
    useState(false);
  const [housePreparation, setHousePreparation] = useState([]);

  const { REACT_APP_HOST_API } = process.env;
  const { setFetchStatus } = useContext(AlumniContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(REACT_APP_HOST_API + "/alumni", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });
    const filteredData = data[0].activities.filter(
      (item) => item.activity_type === "HOUSE_PREPARATION"
    );
    setHousePreparation(filteredData);
  };

  const handleWorkModal = () => {
    setShowWorkModal(true);
  };

  const handleBusinessModal = () => {
    setShowBusinessModal(true);
  };

  const handleEducationBusinessModal = () => {
    setShowEducationBusinessModal(true);
  };

  const handleCollegePreparationModal = () => {
    setShowCollegePreparationModal(true);
  };

  const handleHousePreparationClick = () => {
    if (housePreparation.length === 0 && housePreparation.length < 2) {
      axios
        .post(
          REACT_APP_HOST_API + "/alumni/activity",
          { activityType: "HOUSE_PREPARATION" },
          { headers: { Authorization: "Bearer " + decryptToken("token") } }
        )
        .then(() => {
          setFetchStatus(true);
        });

      setFetchStatus(false);
    }

    if (housePreparation.length > 1) {
      return;
    }
  };

  const handleCancel = () => {
    setShowWorkModal(false);
    setShowBusinessModal(false);
    setShowEducationBusinessModal(false);
    setShowCollegePreparationModal(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleWorkModal}>
        Pekerjaan
      </Menu.Item>
      <Menu.Item key="2" onClick={handleBusinessModal}>
        Wirausaha
      </Menu.Item>
      <Menu.Item key="3" onClick={handleEducationBusinessModal}>
        Mendirikan Lembaga Pendidikan
      </Menu.Item>
      <Menu.Item key="4" onClick={handleCollegePreparationModal}>
        Persiapan Kuliah
      </Menu.Item>
      <Menu.Item key="5" onClick={handleHousePreparationClick}>
        Mengurus Rumah tangga
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="activity">
      <div className={styles["profile-header"]}>
        <h1>Aktifitas</h1>
        <Dropdown overlay={menu} placement="bottom">
          <Button
            type="primary"
            style={{ display: "flex", alignItems: "center" }}
          >
            Tambah Aktifitas
          </Button>
        </Dropdown>
      </div>
      <WorkTable />
      <BusinessTable />
      <EducationBusinessTable />
      <CollegePreparationTable />
      <HousePreparationTable />

      <Modal
        title="Pekerjaan"
        visible={showWorkModal}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} key="1">
            Cancel
          </Button>,
        ]}
      >
        <AddWork onSubmit={handleCancel} />
      </Modal>
      <Modal
        title="Wirausaha"
        visible={showBusinessModal}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} key="1">
            Cancel
          </Button>,
        ]}
      >
        <AddBusiness onSubmit={handleCancel} />
      </Modal>
      <Modal
        title="Mendirikan Lembaga Pendidikan"
        visible={showEducationBusinessModal}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} key="1">
            Cancel
          </Button>,
        ]}
      >
        <AddEducationBusiness onSubmit={handleCancel} />
      </Modal>
      <Modal
        title="Persiapan Kuliah"
        visible={showCollegePreparationModal}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel} key="1">
            Cancel
          </Button>,
        ]}
      >
        <AddCollegePreparation onSubmit={handleCancel} />
      </Modal>
    </section>
  );
};

export default Activity;
