import { Divider, Input } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { encryptToken } from "../Helper";
import Backdrop from "../UI/Backdrop";
import "./Login.css";

const Login = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [responseText, setResponseText] = useState("");

  let history = useHistory();

  const { REACT_APP_HOST_API } = process.env;

  const handleChange = (param) => {
    setInput({ ...input, [param.target.name]: param.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    axios
      .post(REACT_APP_HOST_API + "/login", {
        email: input.email,
        password: input.password,
      })
      .then((response) => {
        Cookies.set("token", response.data.token.token, { expires: 1 });
        Cookies.set("name", response.data.data.first_name, { expires: 1 });
        Cookies.set("role", response.data.data.role, { expires: 1 });
        encryptToken("token", Cookies.get("token"));
        if (response.data.data.role === "alumni") {
          history.push("/alumni/profile");
        }
        if (response.data.data.role === "admin") {
          history.push("/admin/dashboard");
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setResponseText("please enter a valid email or password");
      });
  };

  return (
    <div>
      <Divider className="divider">Form login</Divider>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <Input
            type="email"
            name="email"
            value={input.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password : </label>
          <Input.Password
            type="password"
            name="password"
            value={input.password}
            onChange={handleChange}
            required
          />
        </div>
        {responseText !== "" && <p className="error-text">{responseText}</p>}
        <button className="button">Login</button>
      </form>
      {isLoading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}
    </div>
  );
};

export default Login;
