import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import Backdrop from "../UI/Backdrop";
import { DataInputRegister } from "./DataInputRegister";
const { TextArea } = Input;

const Register = () => {
  const [form] = Form.useForm();
  const [dataInfo, setDataInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubdistrict, setDataSubdistrict] = useState([]);

  let history = useHistory();
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchProvince();
  }, []);

  const fetchProvince = async () => {
    const { data } = await axios.get(REACT_APP_HOST_API + "/country/province");
    setDataProvince(data.data);
  };

  const fetchDistrict = async (value) => {
    const { data } = await axios.get(
      REACT_APP_HOST_API + `/country/province/${value}`
    );
    setDataDistrict(data.data);
  };

  const fetchSubdistrict = async (value) => {
    const { data } = await axios.get(
      REACT_APP_HOST_API + `/country/province/district/${value}`
    );
    setDataSubdistrict(data.data);
  };

  const getExtraInfo = async () => {
    const { data } = await axios.get(REACT_APP_HOST_API + "/extra-info");
    setDataInfo(data);
  };

  useEffect(() => {
    getExtraInfo();
  }, []);

  const handleSuccessOk = () => {
    setShowSuccessModal(false);
    history.push("/verify");
  };

  const handleErrorOk = () => {
    setShowErrorModal(false);
  };

  const handleCancel = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        const values = {
          ...fieldsValue,
          birthDay: fieldsValue["birthDay"].format("YYYY-MM-DD"),
        };
        setIsLoading(true);
        axios
          .post(REACT_APP_HOST_API + "/register", {
            email: values.email,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            gender: values.gender,
            birthPlace: values.birthPlace,
            birthDay: values.birthDay,
            idProvince: values.idProvince,
            idDistrict: values.idDistrict,
            idSubdistrict: values.idSubdistrict,
            address: values.address,
            major: values.major,
            program: values.program,
            graduationYear: values.graduationYear,
            phoneNumber: values.phoneNumber,
            instagram: values.instagram,
            password: values.password,
            password_confirmation: values.password_confirmation,
            extraInfos: [
              {
                extraInfoId: "766585146505789441",
                answer: values.extraInfo1,
              },
              {
                extraInfoId: "766585332750680065",
                answer: values.extraInfo2,
              },
            ],
          })
          .then((res) => {
            setResponseText(res.data.message);
            setShowSuccessModal(true);
            Cookies.set("email", values.email);
          })
          .catch((err) => {
            setResponseText(err.message);
            setShowErrorModal(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((info) => {
        alert("error");
      });
  };

  return (
    <div>
      <Divider style={{ fontSize: "2rem!important" }}>Form Register</Divider>
      <Form
        className="form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        {DataInputRegister.map((data) => {
          if (data.type === "text") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[
                  { required: data.required, message: "harap diisi" },
                  {
                    min: data.minLength,
                    message: `hapa masukkan minimal ${data.minLength} karakter`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
          }
          if (data.type === "email") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <Input type="email" />
              </Form.Item>
            );
          }
          if (data.type === "datePicker") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
            );
          }
          if (data.name === "password") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[
                  { required: data.required, message: "harap diisi" },
                  {
                    min: data.minLength,
                    message: `harap masukkan minimal ${data.minLength} karakter`,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            );
          }
          if (data.name === "password_confirmation") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[
                  { required: data.required, message: "harap diisi" },
                  {
                    min: data.minLength,
                    message: `harap masukkan minimal ${data.minLength} karakter`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error("password harus sama"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            );
          }
          if (data.type === "number") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <InputNumber min={data.min} />
              </Form.Item>
            );
          }
          if (data.name === "idProvince") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <Select
                  showSearch
                  placeholder="cari Provinsi"
                  optionFilterProp="children"
                  onChange={(val) => fetchDistrict(val)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataProvince.map((data) => (
                    <Select.Option key={data.kode} value={data.kode}>
                      {data.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }
          if (data.name === "idDistrict") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <Select
                  showSearch
                  placeholder="cari Kota/Kab"
                  optionFilterProp="children"
                  onChange={(val) => fetchSubdistrict(val)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataDistrict.map((data) => (
                    <Select.Option key={data.kode} value={data.kode}>
                      {data.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }
          if (data.name === "idSubdistrict") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <Select
                  showSearch
                  placeholder="cari daerah"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {dataSubdistrict.map((data) => (
                    <Select.Option key={data.kode} value={data.kode}>
                      {data.nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }

          if (data.type === "select") {
            return (
              <Form.Item
                key={data.id}
                name={data.name}
                label={data.label}
                rules={[{ required: data.required, message: "harap diisi" }]}
              >
                <Select>
                  {data.option.map((option) => (
                    <Select.Option key={option.id} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }
        })}

        <Divider>extra info</Divider>

        {dataInfo && dataInfo.map((extraData, index) => {
          if (extraData.info_type === "SELECT") {
            return (
              <Form.Item
                key={index}
                name="extraInfo1"
                label={extraData.question}
                rules={[{ required: true, message: "harap diisi" }]}
              >
                <Select>
                  <Select.Option value="---">---</Select.Option>
                  <Select.Option value="Februari">Februari</Select.Option>
                  <Select.Option value="Agustus">Agustus</Select.Option>
                  <Select.Option value="Keduanya Bisa">
                    Keduanya Bisa
                  </Select.Option>
                </Select>
              </Form.Item>
            );
          }
          if (extraData.info_type === "TEXT") {
            return (
              <Form.Item
                key={index}
                name="extraInfo2"
                label={extraData.question}
                rules={[{ required: true, message: "harap diisi" }]}
              >
                <TextArea rows="5" />
              </Form.Item>
            );
          }
        })}
        <Button htmlType="submit" type="primary" size="large" block>
          Register
        </Button>
      </Form>
      {isLoading &&
        ReactDOM.createPortal(
          <Backdrop>Loading...</Backdrop>,
          document.getElementById("backdrop")
        )}

      {showSuccessModal && (
        <Modal
          visible={showSuccessModal}
          onOk={handleSuccessOk}
          closable={false}
          footer={[
            <Button key="ok" type="primary" onClick={handleSuccessOk}>
              Ok
            </Button>,
          ]}
        >
          <p style={{ fontSize: 17, textAlign: "center" }}>{responseText}</p>
        </Modal>
      )}
      {showErrorModal && (
        <Modal
          visible={showErrorModal}
          onOk={handleErrorOk}
          onCancel={handleCancel}
        >
          <p style={{ fontSize: 17, textAlign: "center" }}>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default Register;
