import { Divider } from "antd";
import { useParams } from "react-router-dom";
import DataAlumni from "./DataAlumni";
import DetailBusiness from "./DetailBusiness";
import DetailCollegePreparation from "./DetailCollegePreparation";
import DetailEducationBusiness from "./DetailEducationBusiness";
import DetailFormalEducationTable from "./DetailFormalEducationTable";
import DetailHousePreparation from "./DetailHousePreparation";
import DetailInformalEducationTable from "./DetailInformalEducationTable";
import DetailWork from "./DetailWork";

const AlumniDetail = () => {
  const { id } = useParams();
  return (
    <div>
      <DataAlumni id={id} />
      <section className="education">
        <Divider>Pendidikan</Divider>
        <DetailFormalEducationTable id={id} />
        <DetailInformalEducationTable id={id} />
      </section>
      <section className="activity">
        <Divider>Aktifitas</Divider>
        <DetailWork id={id} />
        <DetailBusiness id={id} />
        <DetailEducationBusiness id={id} />
        <DetailCollegePreparation id={id} />
        <DetailHousePreparation id={id} />
      </section>
    </div>
  );
};

export default AlumniDetail;
