import {
  DashboardOutlined,
  LogoutOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;

const LayoutAdmin = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  let history = useHistory();

  if (!Cookies.get("token") || Cookies.get("role") !== "admin") {
    return <Redirect to="/login" />;
  }

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("name");
    Cookies.remove("role");
    history.push("/");
  };

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1" icon={<DashboardOutlined />}>
            <Link to="/admin/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<TableOutlined />}>
            <Link to="/admin/alumni">Data Alumni</Link>
          </Menu.Item>
          <Menu.Item key="3" onClick={handleLogout} icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
          {/* {React.createElement(MenuOutlined, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })} */}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            padding: "30px",
            height: "100%",
            minHeight: "90vh",
            margin: "20px",
            marginTop: "-30px",
            borderRadius: "10px",
            boxShadow: "0 0 7px 1px rgba(0,0,0,.2)",
          }}
        >
          {props.content}
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Build ©2022 Created by{" "}
          <a href="http://it.smafg.sch.id" target="_blank">
            ITFG
          </a>
          v{process.env.REACT_APP_VERSION}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutAdmin;
