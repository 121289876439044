import { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { decryptToken } from "../../../Helper";

const ActivityChart = () => {
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await axios.get(REACT_APP_HOST_API + "/admin/dashboard", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });

    setDataChart({
      labels: data.data.activityPrecentage.map((value) => {
        if (value.activity_type === "COLLEGE_PREPARATION") {
          return "Persiapan Kuliah";
        }
        if (value.activity_type === "HOUSE_PREPARATION") {
          return "Mengurus Rumah Tangga";
        }
        if (value.activity_type === "EDUCATION_BUSINESS") {
          return "Mendirikan Lembaga Pendidikan";
        }
        if (value.activity_type === "WORK") {
          return "Bekerja";
        }
        if (value.activity_type === "BUSINESS") {
          return "Wirausaha";
        }
        if (value.activity_type === "DOING_NOTHING") {
          return "Free";
        }
        if (value.activity_type === "EDUCATION_FORMAL") {
          return "Kuliah";
        }
        if (value.activity_type === "EDUCATION_INFORMAL") {
          return "Belajar nonformal";
        }
        if (value.activity_type === "TOTAL_ALUMNI") {
          return "Jumlah Total";
        }
      }),
      datasets: [
        {
          label: " %",
          data: data.data.activityPrecentage.map((value) => value.precentage),
          backgroundColor: [
            "#18978F",
            "#FF7396",
            "#398AB9",
            "#F0A500",
            "#A760FF",
            "#1363DF",
            "#92A9BD",
            "#F7EC09",
            "#F32424",
          ],
        },
      ],
    });
  };

  return (
    <div className="chart-wrapper">
      <h2>Aktifitas</h2>
      <Bar data={dataChart} />
    </div>
  );
};

export default ActivityChart;
