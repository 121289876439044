import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";
import Activity from "./Activity";
import DetailAlumni from "./DetailAlumni";
import Education from "./Education";

const Profile = () => {
  if (!Cookies.get("token") || Cookies.get("role") !== "alumni") {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <DetailAlumni />
      <Education />
      <Activity />
    </>
  );
};

export default Profile;
