import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { decryptToken } from "../../../Helper";
import * as XLSX from "xlsx";
import Excel from "../../../../assets/img/excel.png";

const AdminTable = () => {
  const [dataAlumni, setDataAlumni] = useState([]);
  const [dataRecap, setDataRecap] = useState([]);
  const [q, setQ] = useState("");
  const [pages, setPages] = useState({
    current_page: 1,
    first_page: 1,
    first_page_url: "",
    last_page: 0,
    last_page_url: "",
    next_page_url: "",
    per_page: 10,
    total: 0,
  });
  const [fetchStatus, setFetchStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { REACT_APP_HOST_API } = process.env;
  let history = useHistory();

  useEffect(() => {
    fetchData();

    const fetchRecap = async () => {
      try {
        const { data } = await axios.get(
          REACT_APP_HOST_API + "/admin/recap/alumni",
          {
            headers: {
              Authorization: "Bearer " + decryptToken("token"),
              "content-type": "application/json",
            },
          }
        );

        setDataRecap(data.all_recap);
      } catch (error) {
        console.log(error);
        alert("Cannot get recap data Alumni");
      }
    };

    fetchRecap();
  }, [fetchStatus]);

  const fetchData = async () => {
    setFetchStatus(false);
    setIsLoading(true);
    const { data } = await axios.get(
      REACT_APP_HOST_API +
        `/admin/alumni?page=${pages.current_page}&limit=${pages.per_page}&keyword=&orderBy=first_name&orderDirection=ASC`,
      {
        headers: { Authorization: "Bearer " + decryptToken("token") },
      }
    );
    setDataAlumni(data.data.data);
    setPages({
      current_page: data.data.meta.current_page,
      first_page: data.data.meta.first_page,
      first_page_url: data.data.meta.first_page_url,
      last_page: data.data.meta.last_page,
      last_page_url: data.data.meta.last_page_url,
      next_page_url: data.data.meta.next_page_url,
      per_page: data.data.meta.per_page,
      total: data.data.meta.total,
    });
    setIsLoading(false);
  };

  const keyData = dataAlumni.slice(0, pages.per_page).map((data, index) => {
    return {
      ...data,
      key: data.id,
      name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
      index: index + 1,
    };
  });

  const columns = [
    { title: "No", dataIndex: "index", align: "center" },
    { title: "Nama", dataIndex: "name", align: "center" },
    { title: "Telephone", dataIndex: "phone_number", align: "center" },
    { title: "Program", dataIndex: "program", align: "center" },
    { title: "Jurusan", dataIndex: "major", align: "center" },
    { title: "Tahun Lulus", dataIndex: "graduation_year", align: "center" },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <Button
            size="small"
            type="primary"
            shape="round"
            onClick={() => history.push("alumni/" + id)}
          >
            detail
          </Button>
        );
      },
    },
  ];

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataRecap);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "Data Alumni.xlsx");
  };

  return (
    <div className="alumni-table">
      <div className="input-search-container">
        <Input
          prefix={<SearchOutlined />}
          type="text"
          size="medium"
          placeholder="Cari Nama..."
          value={q}
          onChange={async (e) => {
            setQ(e.target.value);
            const { data } = await axios.get(
              REACT_APP_HOST_API +
                `/admin/alumni?page=${pages.current_page}&limit=${
                  pages.per_page
                }&keyword=${e.target.value.trim()}&orderBy=first_name&orderDirection=ASC`,
              {
                headers: { Authorization: "Bearer " + decryptToken("token") },
              }
            );

            setDataAlumni(data.data.data);
          }}
          allowClear
          style={{
            borderRadius: "5px",
            borderColor: "#ccc",
          }}
        />
        <img
          src={Excel}
          alt="excel logo"
          className="excel-btn"
          onClick={handleExport}
        />
      </div>
      <Divider>Data Alumni</Divider>
      <Table
        loading={isLoading}
        size="small"
        style={{ overflow: "auto" }}
        columns={columns}
        dataSource={keyData}
        pagination={{
          onChange: (current, size) => {
            setPages((value) => {
              return {
                ...value,
                current_page: current,
                per_page: size,
              };
            });
            setFetchStatus(true);
          },
          showSizeChanger: true,
          defaultCurrent: pages.current_page,
          defaultPageSize: pages.per_page,
          pageSize: pages.per_page,
          total: pages.total,
        }}
      />
    </div>
  );
};

export default AdminTable;
