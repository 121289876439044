import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { decryptToken } from "../../../Helper";
import Card from "../../../UI/Card";

const DetailEducationBusiness = ({ id }) => {
  const [datas, setDatas] = useState([]);
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(
      REACT_APP_HOST_API + "/admin/alumni/" + id,
      {
        headers: { Authorization: "Bearer " + decryptToken("token") },
      }
    );
    const filteredData = data.data[0].activities.filter(
      (item) => item.activity_type === "EDUCATION_BUSINESS"
    );
    setDatas(filteredData);
  };
  const dataKey = datas.map((item) => {
    return {
      ...item,
      key: item.id,
    };
  });

  const columns = [
    {
      title: "Nama Lembaga Pendidikan",
      dataIndex: "ebiz_name",
      align: "center",
    },
    { title: "Bidang Lembaga", dataIndex: "ebiz_field", align: "center" },
    { title: "Tahun Mulai", dataIndex: "ebiz_since", align: "center" },
  ];

  return (
    <Card>
      <Table
        title={() => (
          <h1 className="table-title">Mendirikan Lembaga Pendidikan</h1>
        )}
        size="small"
        dataSource={dataKey}
        columns={columns}
        pagination={false}
      />
    </Card>
  );
};

export default DetailEducationBusiness;
