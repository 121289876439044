export const DataInputRegister = [
  {
    id: "1",
    type: "text",
    name: "firstName",
    label: "Nama Depan",
    required: true,
  },
  {
    id: "2",
    type: "text",
    name: "middleName",
    label: "Nama Tengah",
    required: true,
  },
  {
    id: "3",
    type: "text",
    name: "lastName",
    label: "Nama Akhir",
    required: true,
  },
  {
    id: "4",
    type: "email",
    name: "email",
    label: "Email",
    required: true,
  },
  {
    id: "5",
    type: "select",
    name: "idProvince",
    label: "Provinsi",
    required: true,
  },
  {
    id: "6",
    type: "select",
    name: "idDistrict",
    label: "Kota/Kab",
    required: true,
  },
  {
    id: "7",
    type: "select",
    name: "idSubdistrict",
    label: "Kecamatan",
    required: true,
  },
  {
    id: "8",
    type: "text",
    name: "address",
    label: "Alamat Detail",
    required: true,
    minLength: 25,
  },
  {
    id: "9",
    type: "select",
    name: "gender",
    label: "Gender",
    required: true,
    option: [
      { id: "1", value: null, label: "---" },
      { id: "2", value: "L", label: "Laki-laki" },
      { id: "3", value: "P", label: "Perempuan" },
    ],
  },
  {
    id: "10",
    type: "text",
    name: "birthPlace",
    label: "Tempat Lahir",
    required: true,
  },
  {
    id: "11",
    type: "datePicker",
    name: "birthDay",
    label: "Tanggal Lahir",
    required: true,
  },
  {
    id: "12",
    type: "select",
    name: "major",
    label: "Jurusan",
    required: true,
    option: [
      { id: "1", value: null, label: "---" },
      { id: "2", value: "MIPA", label: "MIPA" },
      { id: "3", value: "IPS", label: "IPS" },
      { id: "4", value: "Bahasa", label: "Bahasa" },
    ],
  },
  {
    id: "13",
    type: "select",
    name: "program",
    label: "Program",
    required: true,
    option: [
      { id: "1", value: null, label: "---" },
      { id: "2", value: "Boarding", label: "Boarding" },
      { id: "3", value: "FullDay", label: "FullDay" },
    ],
  },
  {
    id: "14",
    type: "number",
    name: "graduationYear",
    label: "Tahun Lulus",
    required: true,
    min: 2014,
  },
  {
    id: "15",
    type: "text",
    name: "phoneNumber",
    label: "No Handphone",
    required: true,
    minLength: 8,
  },
  {
    id: "16",
    type: "text",
    name: "instagram",
    label: "Akun Instagram",
    required: true,
  },
  {
    id: "17",
    type: "password",
    name: "password",
    label: "Password",
    required: true,
    minLength: 6,
  },
  {
    id: "18",
    type: "password",
    name: "password_confirmation",
    label: "Konfirm Password",
    required: true,
    minLength: 6,
  },
];
