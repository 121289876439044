import { createContext, useState } from "react";

export const AlumniContext = createContext();
export const AlumniProvider = (props) => {
  const [fetchStatus, setFetchStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataAlumni, setDataAlumni] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [responseText, setResponseText] = useState("");

  return (
    <AlumniContext.Provider
      value={{
        fetchStatus,
        setFetchStatus,
        isLoading,
        setIsLoading,
        dataAlumni,
        setDataAlumni,
        showModal,
        setShowModal,
        responseText,
        setResponseText,
      }}
    >
      {props.children}
    </AlumniContext.Provider>
  );
};
