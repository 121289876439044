import { Descriptions, Divider } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { decryptToken } from "../../../Helper";

const DataAlumni = ({ id }) => {
  const [datas, setDatas] = useState([]);
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(
      REACT_APP_HOST_API + "/admin/alumni/" + id,
      {
        headers: { Authorization: "Bearer " + decryptToken("token") },
      }
    );
    setDatas(data.data);
  };

  const genderGenerate = (value) => {
    if (value === "L") {
      return "Laki-laki";
    }
    if (value === "P") {
      return "Perempuan";
    }
  };

  return (
    <section className="alumni-info">
      <Divider>Alumni Detail</Divider>
      {datas.map((data) => (
        <Descriptions
          key={data.id}
          className="alumni-detail"
          bordered
          column={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          size="small"
        >
          <Descriptions.Item label="Nama">
            {data.first_name + " " + data.middle_name + " " + data.last_name}
          </Descriptions.Item>
          <Descriptions.Item label="Gender">
            {genderGenerate(data.gender)}
          </Descriptions.Item>
          <Descriptions.Item label="Jurusan">{data.major}</Descriptions.Item>
          <Descriptions.Item label="Program">{data.program}</Descriptions.Item>
          <Descriptions.Item label="Tahun Lulus">
            {data.graduation_year}
          </Descriptions.Item>
          <Descriptions.Item label="No Handphone">
            {data.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
          <Descriptions.Item label="Instagram">
            {data.instagram}
          </Descriptions.Item>
          {data.subDistrict !== null &&
          data.district !== null &&
          data.province !== null ? (
            <Descriptions.Item label="Alamat">
              {data.address +
                "." +
                data.subDistrict.nama +
                "." +
                data.district.nama +
                "." +
                data.province.nama}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label="Alamat">No Data</Descriptions.Item>
          )}
          <Descriptions.Item label="Tempat Tanggal Lahir">
            {data.birth_place + " || " + data.birth_day}
          </Descriptions.Item>
        </Descriptions>
      ))}
    </section>
  );
};

export default DataAlumni;
