import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./Home.module.css";

const Home = () => {
  let history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Ahlan Alumni SMA Future Gate</h1>{" "}
        <p className={styles.salam}>السلام عليكم ورحمة الله وبركاته</p>
        <p className={styles.text}>
          Bagaimana kabar kalian? Semoga kalian senantiasa dilindungi Allah
          تعالى.
          <br></br>
          Jadi gini, sampai saat ini kita belum punya database alumni yang
          paling update dan lengkap.
          <br></br>
          Akhirnya membuat kita jadi sulit terhubung satu sama lain, begitu juga
          antara teman-teman dengan sekolah.
          <br></br>
          Karena itu, mulai tahun ini kita sedang berupaya mengumpulkan kembali
          data kalian semua. Dengan cara mudah, aman, dan ramah digital.
          <br></br>
          Yuk isi dan sebarkan link nya ke alumni2 FG yang kalian kenal.
          <br></br>
          Data kalian aman dan terjamin, insyaa Allah.
          <br></br>
          <br></br>
          Jazaakumullahu khairan atas kerjasamanya.
          <br></br>
          <br></br>
        </p>
        {Cookies.get("token") === undefined && (
          <>
            <p className={styles.akun}>
              Silahkan Register bagi yang belum mempunyai akun, atau login bagi
              yang telah memiliki akun
            </p>
            <div className={styles.auth}>
              <Button
                onClick={() => history.push("/register")}
                type="primary"
                size="large"
                style={{ marginRight: 10 }}
              >
                Register
              </Button>
              <Button size="large" onClick={() => history.push("/login")}>
                Login
              </Button>
            </div>
          </>
        )}
        {Cookies.get("token") && (
          <Button
            className={styles["profile-btn"]}
            onClick={() => history.push("/alumni/profile")}
          >
            Profile
          </Button>
        )}
      </div>
    </div>
  );
};

export default Home;
