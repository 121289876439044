import { Input, Modal } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { useContext } from "react";
import * as Yup from "yup";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
import ErrorText from "../../UI/ErrorText";

const initialValues = {
  activityType: "BUSINESS",
  bizName: "",
  bizField: "",
  bizSince: "",
};

const validationSchema = Yup.object({
  activityType: Yup.string().required("harap diisi"),
  bizName: Yup.string().required("harap diisi"),
  bizField: Yup.string().required("harap diisi"),
  bizSince: Yup.string().required("harap diisi"),
});

const AddBusiness = (props) => {
  const {
    showModal,
    setShowModal,
    responseText,
    setResponseText,
    setFetchStatus,
  } = useContext(AlumniContext);
  const { REACT_APP_HOST_API } = process.env;

  const formik = useFormik({
    initialValues,
    onSubmit: (value) => {
      axios
        .post(
          REACT_APP_HOST_API + "/alumni/activity",
          {
            activityType: value.activityType,
            bizName: value.bizName,
            bizField: value.bizField,
            bizSince: value.bizSince,
          },
          { headers: { Authorization: "Bearer " + decryptToken("token") } }
        )
        .then((res) => {
          setResponseText(res.data.message);
          setShowModal(true);
          formik.resetForm();
          setFetchStatus(true);
        })
        .catch((err) => {
          setResponseText("Gagal Input Data " + err.message);
          setShowModal(true);
        });

      setFetchStatus(false);
    },
    validationSchema,
  });

  const handleOk = () => {
    setShowModal(false);
    props.onSubmit();
  };

  const handleCancel = () => {
    setShowModal(false);
    props.onSubmit();
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group" style={{ display: "none" }}>
          <label htmlFor="activityType">Jenis Aktifitas</label>
          <Input
            type="text"
            id="activityType"
            name="activityType"
            readOnly
            value={formik.values.activityType}
            required
          ></Input>
          {formik.errors.activityType && formik.touched.activityType && (
            <ErrorText>{formik.errors.activityType}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="bizName">Nama usaha</label>
          <Input
            type="text"
            id="bizName"
            name="bizName"
            {...formik.getFieldProps("bizName")}
          />
          {formik.errors.bizName && formik.touched.bizName && (
            <ErrorText>{formik.errors.bizName}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="bizField">Bidang usaha</label>
          <Input
            type="text"
            id="bizField"
            name="bizField"
            {...formik.getFieldProps("bizField")}
          />
          {formik.errors.bizField && formik.touched.bizField && (
            <ErrorText>{formik.errors.bizField}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="bizSince">Tahun mulai</label>
          <Input
            type="text"
            id="bizSince"
            name="bizSince"
            {...formik.getFieldProps("bizSince")}
          />
          {formik.errors.bizSince && formik.touched.bizSince && (
            <ErrorText>{formik.errors.bizSince}</ErrorText>
          )}
        </div>
        <button type="submit" className="button">
          Submit
        </button>
      </form>
      {showModal && (
        <Modal visible={showModal} onOk={handleOk} onCancel={handleCancel}>
          <p>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default AddBusiness;
