import { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { decryptToken } from "../../../Helper";

const InstitutionNameChart = () => {
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await axios.get(REACT_APP_HOST_API + "/admin/dashboard", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });

    setDataChart({
      labels: data.data.institutionNamePrecentage.map(
        (value) => value.institution
      ),
      datasets: [
        {
          label: "Jumlah",
          data: data.data.institutionNamePrecentage.map((value) => value.total),
          backgroundColor: ["#18978F", "#FF7396"],
        },
      ],
    });
  };

  return (
    <div className="chart-wrapper">
      <h2>Nama Universitas</h2>
      <Bar data={dataChart} />
    </div>
  );
};

export default InstitutionNameChart;
