import { useState, useEffect } from "react";
import axios from "axios";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { decryptToken } from "../../../Helper";

const GenderChart = () => {
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await axios.get(REACT_APP_HOST_API + "/admin/dashboard", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });

    setDataChart({
      labels: data.data.genderPrecentage.map((value) => {
        if (value.gender === "L") {
          return "Laki-laki";
        }
        if (value.gender === "P") {
          return "Perempuan";
        }
      }),
      datasets: [
        {
          data: data.data.genderPrecentage.map((value) => value.total),
          backgroundColor: ["#18978F", "#FF7396"],
        },
      ],
    });
  };

  return (
    <div className="chart-wrapper">
      <h2>Gender</h2>
      <Pie data={dataChart} />
    </div>
  );
};

export default GenderChart;
