import axios from "axios";
import { useEffect, useState } from "react";
import { decryptToken } from "../../../Helper";
import Card from "../../../UI/Card";

const DetailHousePreparation = ({ id }) => {
  const [datas, setDatas] = useState([]);
  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(
      REACT_APP_HOST_API + "/admin/alumni/" + id,
      {
        headers: { Authorization: "Bearer " + decryptToken("token") },
      }
    );
    const filteredData = data.data[0].activities.filter(
      (item) => item.activity_type === "HOUSE_PREPARATION"
    );
    setDatas(filteredData);
  };

  return (
    <Card>
      {datas.length > 0 && datas.length < 2 && (
        <>
          {datas.map((data) => (
            <h1
              key={data.id}
              style={{ fontSize: 17, border: "1px solid #ccc", padding: 10 }}
            >
              Mengurus Rumah Tangga
            </h1>
          ))}
        </>
      )}
    </Card>
  );
};

export default DetailHousePreparation;
