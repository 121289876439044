import { Input, Modal } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { useContext } from "react";
import * as Yup from "yup";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
import ErrorText from "../../UI/ErrorText";

const initialValues = {
  activityType: "EDUCATION_BUSINESS",
  ebizName: "",
  ebizField: "",
  ebizSince: "",
};

const validationSchema = Yup.object({
  activityType: Yup.string().required("harap diisi"),
  ebizName: Yup.string().required("harap diisi"),
  ebizField: Yup.string().required("harap diisi"),
  ebizSince: Yup.string().required("harap diisi"),
});

const AddBusiness = (props) => {
  const {
    showModal,
    setShowModal,
    responseText,
    setResponseText,
    setFetchStatus,
  } = useContext(AlumniContext);
  const { REACT_APP_HOST_API } = process.env;

  const formik = useFormik({
    initialValues,
    onSubmit: (value) => {
      axios
        .post(
          REACT_APP_HOST_API + "/alumni/activity",
          {
            activityType: value.activityType,
            ebizName: value.ebizName,
            ebizField: value.ebizField,
            ebizSince: value.ebizSince,
          },
          { headers: { Authorization: "Bearer " + decryptToken("token") } }
        )
        .then((res) => {
          setResponseText(res.data.message);
          setShowModal(true);
          formik.resetForm();
          setFetchStatus(true);
        })
        .catch((err) => {
          setResponseText("Gagal Input Data " + err.message);
          setShowModal(true);
        });

      setFetchStatus(false);
    },
    validationSchema,
  });

  const handleOk = () => {
    setShowModal(false);
    props.onSubmit();
  };

  const handleCancel = () => {
    setShowModal(false);
    props.onSubmit();
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group" style={{ display: "none" }}>
          <label htmlFor="activityType">Jenis Aktifitas</label>
          <Input
            type="text"
            id="activityType"
            name="activityType"
            readOnly
            value={formik.values.activityType}
            required
          ></Input>
          {formik.errors.activityType && formik.touched.activityType && (
            <ErrorText>{formik.errors.activityType}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="ebizName">Nama Lembaga</label>
          <Input
            type="text"
            id="ebizName"
            name="ebizName"
            {...formik.getFieldProps("ebizName")}
          />
          {formik.errors.ebizName && formik.touched.ebizName && (
            <ErrorText>{formik.errors.ebizName}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="ebizField">Bidang Lembaga</label>
          <Input
            type="text"
            id="ebizField"
            name="ebizField"
            {...formik.getFieldProps("ebizField")}
          />
          {formik.errors.ebizField && formik.touched.ebizField && (
            <ErrorText>{formik.errors.ebizField}</ErrorText>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="ebizSince">Tahun mulai</label>
          <Input
            type="text"
            id="ebizSince"
            name="ebizSince"
            {...formik.getFieldProps("ebizSince")}
          />
          {formik.errors.ebizSince && formik.touched.ebizSince && (
            <ErrorText>{formik.errors.ebizSince}</ErrorText>
          )}
        </div>
        <button type="submit" className="button">
          Submit
        </button>
      </form>
      {showModal && (
        <Modal visible={showModal} onOk={handleOk} onCancel={handleCancel}>
          <p>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default AddBusiness;
