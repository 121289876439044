import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const { REACT_APP_SECRET_KEY } = process.env;

export const encryptToken = (key, value) => {
  const encryptedValue = CryptoJS.AES.encrypt(
    value,
    REACT_APP_SECRET_KEY
  ).toString();
  Cookies.set(key, encryptedValue);
};

export const decryptToken = (key) => {
  const value = Cookies.get(key);
  const decryptValue = CryptoJS.AES.decrypt(
    value,
    REACT_APP_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  return decryptValue;
};
