import { Input, Modal, Select } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
const { Option } = Select;

const initialValues = {
  institution: "",
  institutionCountry: "Indonesia",
  institutionType: "",
  idInstitution: "",
  degree: "",
  year: 0,
  major: "",
  entranceType: "",
};

const validationSchema = Yup.object({
  institution: Yup.string().required("harap diisi !"),
  institutionCountry: Yup.string().required("harap diisi !"),
  institutionType: Yup.string().required("harap diisi !"),
  degree: Yup.string().required("harap diisi !"),
  year: Yup.number().required("harap diisi !"),
  major: Yup.string().required("harap diisi !"),
  entranceType: Yup.string().required("harap diisi !"),
});

const AddFormalEducation = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [dataUniversitas, setDataUniversitas] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const { fetchStatus, setFetchStatus } = useContext(AlumniContext);

  const { REACT_APP_HOST_API } = process.env;

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFetchStatus(true);

      axios
        .post(
          REACT_APP_HOST_API + "/alumni/education/formal/",
          {
            institution: values.institution,
            institutionCountry: values.institutionCountry,
            institutionType: values.institutionType,
            idInstitution: values.idInstitution,
            degree: values.degree,
            year: values.year,
            major: values.major,
            entranceType: values.entranceType,
          },
          {
            headers: { Authorization: "Bearer " + decryptToken("token") },
          }
        )
        .then((response) => {
          setResponseText(response.data.message);
          setShowModal(true);
          formik.resetForm();
          setFetchStatus(true);
        })
        .catch((err) => {
          setResponseText(err.message);
          setShowModal(true);
        });

      setFetchStatus(false);
    },
    validationSchema,
  });

  useEffect(() => {
    fetchData();
    fetchCountry();
  }, [fetchStatus]);

  const fetchData = async () => {
    let { data } = await axios.get(REACT_APP_HOST_API + "/institution-list", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });
    setDataUniversitas(data.data);
  };

  const fetchCountry = async () => {
    const { data } = await axios.get(REACT_APP_HOST_API + "/country-list");
    setCountryList(data.data);
  };

  const handleOk = () => {
    setShowModal(false);
    props.onSubmit();
  };

  const handleCancel = () => {
    setShowModal(false);
    props.onSubmit();
  };

  const onChange = (value) => {
    formik.values.institution = value;
  };

  const onCountryChange = (value) => {
    formik.values.institutionCountry = value;
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="form formal-education">
        <div className="form-group">
          <label htmlFor="institutionType">Jenis Universitas :</label>
          <select
            name="institutionType"
            id="institutionType"
            {...formik.getFieldProps("institutionType")}
          >
            <option value="">---</option>
            <option value="PTN">Negeri</option>
            <option value="PTS">Swasta</option>
            <option value="PTSD">Swasta Diniyyah</option>
            <option value="PTLU">Universitas Luar Negeri</option>
          </select>
          {formik.touched.institutionType && formik.errors.institutionType && (
            <p className="error-text">{formik.errors.institutionType}</p>
          )}
        </div>
        {(formik.values.institutionType === "PTN" ||
          formik.values.institutionType === "PTS" ||
          formik.values.institutionType === "PTSD") && (
          <Input
            name="institutionCountry"
            value={formik.values.institutionCountry}
            hidden
            required
          />
        )}
        {formik.values.institutionType === "PTN" && (
          <>
            <div className="form-group">
              <label htmlFor="institution">Nama Universitas :</label>
              <Select
                name="institution"
                showSearch
                placeholder="cari universitas"
                optionFilterProp="children"
                onChange={onChange}
                onBlur={() => formik.setFieldTouched("institution", true)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                required
              >
                {dataUniversitas.map((universitas) => {
                  return (
                    <Option key={universitas.id} value={universitas.nama}>
                      {universitas.nama}
                    </Option>
                  );
                })}
              </Select>
              {formik.touched.institution && formik.errors.institution && (
                <p className="error-text">{formik.errors.institution}</p>
              )}
            </div>
          </>
        )}
        {(formik.values.institutionType === "PTS" ||
          formik.values.institutionType === "PTSD") && (
          <div className="form-group">
            <label htmlFor="institution">Nama Universitas :</label>
            <Input
              type="text"
              name="institution"
              {...formik.getFieldProps("institution")}
            />
            {formik.touched.institution && formik.errors.institution && (
              <p className="error-text">{formik.errors.institution}</p>
            )}
          </div>
        )}
        {formik.values.institutionType === "PTLU" && (
          <>
            <div className="form-group">
              <label htmlFor="institutionCountry">Negara :</label>
              <Select
                name="institutionCountry"
                showSearch
                placeholder="cari Negara"
                optionFilterProp="children"
                onChange={onCountryChange}
                onBlur={() =>
                  formik.setFieldTouched("institutionCountry", true)
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countryList.map((country) => {
                  return (
                    <Option key={country.code} value={country.name}>
                      {country.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="form-group">
              <label htmlFor="institution">Nama Universitas</label>
              <Input
                type="text"
                name="institution"
                {...formik.getFieldProps("institution")}
              />
              {formik.touched.institution && formik.errors.institution && (
                <p className="error-text">{formik.errors.institution}</p>
              )}
            </div>
          </>
        )}
        <div className="form-group">
          <label htmlFor="degree">Jenjang :</label>
          <select name="degree" id="degree" {...formik.getFieldProps("degree")}>
            <option value="">---</option>
            <option value="D1">D1</option>
            <option value="D2">D2</option>
            <option value="D3">D3</option>
            <option value="D4">D4</option>
            <option value="S1">S1</option>
            <option value="S2">S2</option>
            <option value="S3">S3</option>
          </select>
          {formik.touched.degree && formik.errors.degree && (
            <p className="error-text">{formik.errors.degree}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="year">Tahun Lulus :</label>
          <Input
            type="number"
            name="year"
            id="year"
            min="2014"
            {...formik.getFieldProps("year")}
          />
          {formik.touched.year && formik.errors.year && (
            <p className="error-text">{formik.errors.year}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="major">Jurusan :</label>
          <Input
            type="text"
            name="major"
            id="major"
            {...formik.getFieldProps("major")}
          />
          {formik.touched.major && formik.errors.major && (
            <p className="error-text">{formik.errors.major}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="entranceType">Jalur Masuk :</label>
          <select
            name="entranceType"
            id="entranceType"
            {...formik.getFieldProps("entranceType")}
          >
            <option value="">---</option>
            <option value="SNMPTN">SNMPTN</option>
            <option value="SBMPTN">SBMPTN</option>
            <option value="Seleksi Mandiri (Rapor)">
              Seleksi Mandiri (Rapor)
            </option>
            <option value="Seleksi Mandiri (Tes)">Seleksi Mandiri (Tes)</option>
          </select>
          {formik.touched.entranceType && formik.errors.entranceType && (
            <p className="error-text">{formik.errors.entranceType}</p>
          )}
        </div>
        <button type="submit" className="button">
          submit
        </button>
      </form>
      {showModal && (
        <Modal visible={showModal} onOk={handleOk} onCancel={handleCancel}>
          <p style={{ fontSize: 17, textAlign: "center" }}>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default AddFormalEducation;
