import { Button, Modal, Table } from "antd";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";
import Card from "../../UI/Card";

const WorkTable = () => {
  const [workData, setWorkData] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [dataId, setDataId] = useState("");
  const { REACT_APP_HOST_API } = process.env;
  const { fetchStatus, setFetchStatus } = useContext(AlumniContext);

  useEffect(() => {
    fetchData();
  }, [fetchStatus]);

  const fetchData = async () => {
    const { data } = await axios.get(REACT_APP_HOST_API + "/alumni", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });

    const newData = data[0].activities.filter(
      (x) => x.activity_type === "WORK"
    );
    setWorkData(newData);
  };

  const keyData = workData.map(({ ...item }) => ({
    ...item,
    key: item.id,
  }));

  const handleShowCancelModal = (id) => {
    setDataId(id);
    setShowCancelModal(true);
  };

  const handleCancel = () => {
    setShowCancelModal(false);
  };

  const columns = [
    { title: "Nama Perusahaan", dataIndex: "work_company", align: "center" },
    { title: "Tanggung Jawab", dataIndex: "work_field", align: "center" },
    { title: "Tahun Mulai", dataIndex: "work_in", align: "center" },
    { title: "Jabatan", dataIndex: "work_occupation", align: "center" },
    {
      title: "Action",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <>
            <Button
              type="danger"
              size="small"
              shape="round"
              onClick={() => handleShowCancelModal(id)}
            >
              Delete
            </Button>
            <Modal
              visible={showCancelModal}
              onCancel={handleCancel}
              onOk={() => {
                axios
                  .delete(REACT_APP_HOST_API + "/alumni/activity/" + dataId, {
                    headers: {
                      Authorization: "Bearer " + decryptToken("token"),
                    },
                  })
                  .then(() => {
                    setShowCancelModal(false);
                    setFetchStatus(true);
                  });
                setFetchStatus(false);
              }}
            >
              <p className="content-modal">yakin ingin menghapus ?</p>
            </Modal>
          </>
        );
      },
    },
  ];

  return (
    <>
      {keyData.length > 0 && (
        <Card>
          <Table
            size="small"
            title={() => <h1 className="table-title">Pekerjaan</h1>}
            dataSource={keyData}
            columns={columns}
            pagination={false}
          ></Table>
        </Card>
      )}
    </>
  );
};

export default WorkTable;
