import { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { decryptToken } from "../../../Helper";

const GatheringChart = () => {
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const { REACT_APP_HOST_API } = process.env;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await axios.get(REACT_APP_HOST_API + "/admin/dashboard", {
      headers: { Authorization: "Bearer " + decryptToken("token") },
    });

    setDataChart({
      labels: data.data.alumniGatheringPrecentage.map((value) => value.month),
      datasets: [
        {
          label: "%",
          data: data.data.alumniGatheringPrecentage.map(
            (value) => value.precentage
          ),
          backgroundColor: ["#18978F", "#FF7396", "#398AB9", "#F0A500"],
        },
      ],
    });
  };

  return (
    <div className="chart-wrapper chart-institution-type">
      <h2>Kesiapan Reuni</h2>
      <Bar data={dataChart} />
    </div>
  );
};

export default GatheringChart;
