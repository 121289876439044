import { Dropdown, Layout, Menu } from "antd";
import Cookies from "js-cookie";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AlumniContext } from "../../context/AlumniContext";
const { Header, Content, Footer } = Layout;

const LayoutProfile = (props) => {
  const { setFetchStatus } = useContext(AlumniContext);

  let history = useHistory();

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("email");
    Cookies.remove("name");
    Cookies.remove("role");
    setFetchStatus(true);
    history.push("/");
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className="layout">
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">
            <Link to="/">Home</Link>
          </Menu.Item>
          <Dropdown overlay={menu} placement="bottom">
            <Menu.Item key="2" style={{ marginLeft: "auto" }}>
              {Cookies.get("name")}
            </Menu.Item>
          </Dropdown>
        </Menu>
      </Header>
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">{props.content}</div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Build ©2022 Created by{" "}
        <a href="http://it.smafg.sch.id" target="_blank">
          ITFG
        </a>{" "}
        v{process.env.REACT_APP_VERSION}
      </Footer>
    </Layout>
  );
};

export default LayoutProfile;
