import "./App.css";
import { AlumniProvider } from "./context/AlumniContext";
import Routes from "./routes/Routes";

function App() {
  return (
    <AlumniProvider>
      <Routes />
    </AlumniProvider>
  );
}

export default App;
