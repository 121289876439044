import { Input, Modal } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { AlumniContext } from "../../../context/AlumniContext";
import { decryptToken } from "../../Helper";

const AddInformalEducation = (props) => {
  const [input, setInput] = useState({
    institution: "",
    year: 0,
    major: "",
    duration: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [responseText, setResponseText] = useState("");

  const { setFetchStatus } = useContext(AlumniContext);

  const resetInput = () => {
    setInput({
      institution: "",
      year: 0,
      major: "",
      duration: "",
    });
  };

  const handleChange = (param) => {
    setInput({ ...input, [param.target.name]: param.target.value });
  };

  const { REACT_APP_HOST_API } = process.env;

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        REACT_APP_HOST_API + "/alumni/education/informal/",
        {
          institution: input.institution,
          year: input.year,
          major: input.major,
          duration: input.duration,
        },
        {
          headers: { Authorization: "Bearer " + decryptToken("token") },
        }
      )
      .then((response) => {
        setResponseText(response.data.message);
        setShowModal(true);
        resetInput();
        setFetchStatus(true);
      })
      .catch((err) => {
        setResponseText(err.message);
        setShowModal(true);
      });

    setFetchStatus(false);
  };

  const handleOk = () => {
    setShowModal(false);
    props.onSubmit();
  };

  const handleCancel = () => {
    setShowModal(false);
    props.onSubmit();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="form informal-education">
        <div className="form-group">
          <label htmlFor="institution">Nama Institusi :</label>
          <Input
            type="text"
            name="institution"
            id="institution"
            onChange={handleChange}
            value={input.institution}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="year">Tahun Lulus :</label>
          <Input
            type="number"
            name="year"
            id="year"
            onChange={handleChange}
            value={input.year}
            min="2000"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="major">Bidang :</label>
          <Input
            type="text"
            name="major"
            id="major"
            onChange={handleChange}
            value={input.major}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="duration">Durasi Pendidikan :</label>
          <Input
            type="text"
            name="duration"
            id="duration"
            onChange={handleChange}
            value={input.duration}
            required
          />
        </div>
        <button type="submit" className="button">
          submit
        </button>
      </form>
      {showModal && (
        <Modal visible={showModal} onOk={handleOk} onCancel={handleCancel}>
          <p style={{ fontSize: 17, textAlign: "center" }}>{responseText}</p>
        </Modal>
      )}
    </div>
  );
};

export default AddInformalEducation;
